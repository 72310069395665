/*
  GRID inspired by: https://github.com/basscss/basscss
*/


/*--MB Basics--*/
.table {
  display: table;
}

.block {
  display: block;
}

.hide {
  display: none;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.fixed {
  position: fixed;
}

.relative {
  position: relative;
}


/*--RESPONSIVE COND--*/
.no-float {
  float: none;
}

.lg-only,
.md-only,
.sm-only,

.tablet-and-below,
.tab-and-below,
.mobile-only {
  display: none !important;
}

.lg-and-below,
.md-and-below,
.sm-and-below,
.tab-portrait-and-below,
.tablet-portrait-and-below,
.tablet-only {
  display: none;
}

.desktop-only {
  display: inherit;
}

.not-mobile {
  /* display: inherit; */
}

.xl-only,
.tablet-landscape-and-above,
.above-tab-portrait {
  display: block;
}



/*--SPACING--*/
.large-section-block {
  margin-bottom: 150px !important;
  z-index: 9;
  position: relative;
}

.section-block {
  margin-bottom: 100px !important;
  z-index: 9;
  position: relative;
}

.threeqtr-section-block {
  margin-bottom: 90px !important;
  z-index: 9;
  position: relative;
}

.half-section-block {
  margin-bottom: 45px !important;
  z-index: 9;
  position: relative;
}

.qtr-section-block {
  margin-bottom: 25px !important;
  z-index: 9;
  position: relative;
}

.eighth-section-block {
  margin-bottom: 7px !important;
  z-index: 9;
  position: relative;
}





/*--WRAPPERS--*/
.xlarge-wrapper {
  width: 95%;
  margin:0 auto;
  padding: 0;
  position: relative;
  display: block;
  max-width: none;
}

.large-wrapper {
  width: 95%;
  margin:0 auto;
  padding: 0;
  position: relative;
  display: block;
  max-width: 1600px;
}

.wrapper {
  width: 80%;
  margin:0 auto;
  padding: 0;
  position: relative;
  display: block;
  max-width: 1265px;
}

.medium-wrapper {
  width: 65%;
  margin:0 auto;
  padding: 0;
  position: relative;
  display: block;
  max-width: 1024px;
}

.small-wrapper {
  width: 75%;
  margin:0 auto;
  padding: 0;
  position: relative;
  display: block;
  max-width: 500px;
}

.xsmall-wrapper {
  width: 70%;
  margin:0 auto;
  padding: 0;
  position: relative;
  display: block;
  max-width: 650px;
}




/* Basscss Layout */

.inline       { display: inline }
.block        { display: block }
.inline-block { display: inline-block }
.table        { display: table }
.table-cell   { display: table-cell }

.overflow-hidden { overflow: hidden }
.overflow-scroll { overflow: scroll }
.overflow-auto   { overflow: auto }

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table
}
.clearfix:after { clear: both }

.left  { float: left }
.right { float: right }

.border-box { box-sizing: border-box }




/*--GRID--*/
.col {
  float: left;
  box-sizing: border-box;
}

.col-right {
  float: right;
  box-sizing: border-box;
}

.col-1 {
  width: calc(1/12 * 100%);
}

.col-2 {
  width: calc(2/12 * 100%);
}

.col-3 {
  width: calc(3/12 * 100%);
}

.col-4 {
  width: calc(4/12 * 100%);
}

.col-5 {
  width: calc(5/12 * 100%);
}

.col-6 {
  width: calc(6/12 * 100%);
}

.col-7 {
  width: calc(7/12 * 100%);
}

.col-8 {
  width: calc(8/12 * 100%);
}

.col-9 {
  width: calc(9/12 * 100%);
}

.col-10 {
  width: calc(10/12 * 100%);
}

.col-11 {
  width: calc(11/12 * 100%);
}

.col-12 {
  width: 100%;
}

.p0  { padding: 0 }
.pt0 { padding-top: 0 }
.pr0 { padding-right: 0 }
.pb0 { padding-bottom: 0 }
.pl0 { padding-left: 0 }
.px0 { padding-left: 0; padding-right:  0 }
.py0 { padding-top: 0;  padding-bottom: 0 }

.p1  { padding:        30px }
.pt1 { padding-top:    30px }
.pr1 { padding-right:  30px }
.pb1 { padding-bottom: 30px }
.pl1 { padding-left:   30px }
.py1 { padding-top:    30px; padding-bottom: 30px }
.px1 { padding-left:   30px; padding-right:  30px }

.p2  { padding:        20px }
.pt2 { padding-top:    20px }
.pr2 { padding-right:  20px }
.pb2 { padding-bottom: 20px }
.pl2 { padding-left:   20px }
.py2 { padding-top:    20px; padding-bottom: 20px }
.px2 { padding-left:   20px; padding-right:  20px }

.p3  { padding:        15px; }
.pt3 { padding-top:    15px; }
.pr3 { padding-right:  15px; }
.pb3 { padding-bottom: 15px; }
.pl3 { padding-left:   15px; }
.py3 { padding-top:    15px; padding-bottom: 15px; }
.px3 { padding-left:   15px; padding-right:  15px; }

.p4  { padding:        10px }
.pt4 { padding-top:    10px }
.pr4 { padding-right:  10px }
.pb4 { padding-bottom: 10px }
.pl4 { padding-left:   10px }
.py4 { padding-top:    10px; padding-bottom: 10px }
.px4 { padding-left:   10px; padding-right:  10px }



@media all and (max-width: 1100px) {


  .lg-and-below,
  .lg-only,
  .tablet-only,
  .tablet-and-below {
    display: block !important;
  }

  .xl-only,
  .md-only,
  .sm-only,
  .desktop-only {
    display: none !important;
  }

  .lg-no-float {
    float: none !important;
  }


  /*--SPACING--*/
  .eighth-section-block {
    margin-bottom:  !important;
  }

  .qtr-section-block {
    margin-bottom: 18px !important;
  }

  .half-section-block {
    margin-bottom: 35px !important;
  }

  .section-block {
    margin-bottom: 70px !important;
  }

  .threeqtr-section-block {
    margin-bottom: 55px !important;
  }

  .large-section-block {
    margin-bottom:  !important;
  }

  .lg-eighth-section-block {
    margin-bottom:  !important;
  }

  .lg-qtr-section-block {
    margin-bottom:  !important;
  }

  .lg-half-section-block {
    margin-bottom:  !important;
  }

  .lg-section-block {
    margin-bottom:  !important;
  }

  .lg-large-section-block {
    margin-bottom:  !important;
  }


  /*--WRAPPERS--*/
  .xsmall-wrapper {
    width: ;
    max-width: ;
  }

  .small-wrapper {
    width: ;
    max-width: ; 
  }

  .wrapper {
    width: 90%;
    max-width: 960px;
  }

  .medium-wrapper {
    width: ;
  }

  .large-wrapper {
    width: ;
  }

  .xlarge-wrapper {
    width: ;
  }



  /*--GRID--*/
  .lg-col {
    float: left;
    box-sizing: border-box;
  }

  .lg-col-right {
    float: right;
    box-sizing: border-box;
  }

  .lg-col-1 {
    width: calc(1/12 * 100%);
  }

  .lg-col-2 {
    width: calc(2/12 * 100%);
  }

  .lg-col-3 {
    width: calc(3/12 * 100%);
  }

  .lg-col-4 {
    width: calc(4/12 * 100%);
  }

  .lg-col-5 {
    width: calc(5/12 * 100%);
  }

  .lg-col-6 {
    width: calc(6/12 * 100%);
  }

  .lg-col-7 {
    width: calc(7/12 * 100%);
  }

  .lg-col-8 {
    width: calc(8/12 * 100%);
  }

  .lg-col-9 {
    width: calc(9/12 * 100%);
  }

  .lg-col-10 {
    width: calc(10/12 * 100%);
  }

  .lg-col-11 {
    width: calc(11/12 * 100%);
  }

  .lg-col-12 {
    width: 100%;
  }

  .lg-p0  { padding: 0 }
  .lg-pt0 { padding-top: 0 }
  .lg-pr0 { padding-right: 0 }
  .lg-pb0 { padding-bottom: 0 }
  .lg-pl0 { padding-left: 0 }
  .lg-px0 { padding-left: 0; padding-right:  0 }
  .lg-py0 { padding-top: 0;  padding-bottom: 0 }

  .lg-p1  { padding:        30px }
  .lg-pt1 { padding-top:    30px }
  .lg-pr1 { padding-right:  30px }
  .lg-pb1 { padding-bottom: 30px }
  .lg-pl1 { padding-left:   30px }
  .lg-py1 { padding-top:    30px; padding-bottom: 30px }
  .lg-px1 { padding-left:   30px; padding-right:  30px }

  .lg-p2  { padding:        20px }
  .lg-pt2 { padding-top:    20px }
  .lg-pr2 { padding-right:  20px }
  .lg-pb2 { padding-bottom: 20px }
  .lg-pl2 { padding-left:   20px }
  .lg-py2 { padding-top:    20px; padding-bottom: 20px }
  .lg-px2 { padding-left:   20px; padding-right:  20px }

  .lg-p3  { padding:        15px; }
  .lg-pt3 { padding-top:    15px; }
  .lg-pr3 { padding-right:  15px; }
  .lg-pb3 { padding-bottom: 15px; }
  .lg-pl3 { padding-left:   15px; }
  .lg-py3 { padding-top:    15px; padding-bottom: 15px; }
  .lg-px3 { padding-left:   15px; padding-right:  15px; }

  .lg-p4  { padding:        10px }
  .lg-pt4 { padding-top:    10px }
  .lg-pr4 { padding-right:  10px }
  .lg-pb4 { padding-bottom: 10px }
  .lg-pl4 { padding-left:   10px }
  .lg-py4 { padding-top:    10px; padding-bottom: 10px }
  .lg-px4 { padding-left:   10px; padding-right:  10px }  

}


@media all and (max-width: 770px) {


  /*--RESPONSIVE COND--*/
  
  
  .md-no-float {
    float: none !important;
  }

  .tablet-landscape-and-above {
    display: none;
  }

  .md-and-below,
  .tablet-portrait-and-below {
    display: block;
  }

  .md-only {
    display: none !important;
  }

  .xl-only
  .lg-only {
    display: block !important; 
  }



  /*--SPACING--*/
  .eighth-section-block {
    margin-bottom:  !important;
  }

  .qtr-section-block {
    margin-bottom:  !important;
  }

  .half-section-block {
    margin-bottom:  !important;
  }

  .section-block {
    margin-bottom:  !important;
  }

  .large-section-block {
    margin-bottom:  !important;
  }

  .md-eighth-section-block {
    margin-bottom:  !important;
  }

  .md-qtr-section-block {
    margin-bottom:  !important;
  }

  .md-half-section-block {
    margin-bottom:  !important;
  }

  .md-section-block {
    margin-bottom:  !important;
  }

  .md-large-section-block {
    margin-bottom:  !important;
  }



  /*--WRAPPERS--*/
  .xsmall-wrapper {
    width: ;
    max-width: ;
  }

  .small-wrapper {
    width: ;
    max-width: ; 
  }

  .wrapper {
    width: 90%;
    max-width: 960px;
  }

  .medium-wrapper {
    width: 85%;
  }

  .large-wrapper {
    width: ;
  }

  .xlarge-wrapper {
    width: ;
  }


  /*--GRID--*/
  .md-col {
    float: left;
    box-sizing: border-box;
  }

  .md-col-right {
    float: right;
    box-sizing: border-box;
  }

  .md-col-1 {
    width: calc(1/12 * 100%);
  }

  .md-col-2 {
    width: calc(2/12 * 100%);
  }

  .md-col-3 {
    width: calc(3/12 * 100%);
  }

  .md-col-4 {
    width: calc(4/12 * 100%);
  }

  .md-col-5 {
    width: calc(5/12 * 100%);
  }

  .md-col-6 {
    width: calc(6/12 * 100%);
  }

  .md-col-7 {
    width: calc(7/12 * 100%);
  }

  .md-col-8 {
    width: calc(8/12 * 100%);
  }

  .md-col-9 {
    width: calc(9/12 * 100%);
  }

  .md-col-10 {
    width: calc(10/12 * 100%);
  }

  .md-col-11 {
    width: calc(11/12 * 100%);
  }

  .md-col-12 {
    width: 100%;
  }

  .md-p0  { padding: 0 }
  .md-pt0 { padding-top: 0 }
  .md-pr0 { padding-right: 0 }
  .md-pb0 { padding-bottom: 0 }
  .md-pl0 { padding-left: 0 }
  .md-px0 { padding-left: 0; padding-right:  0 }
  .md-py0 { padding-top: 0;  padding-bottom: 0 }

  .md-p1  { padding:        30px }
  .md-pt1 { padding-top:    30px }
  .md-pr1 { padding-right:  30px }
  .md-pb1 { padding-bottom: 30px }
  .md-pl1 { padding-left:   30px }
  .md-py1 { padding-top:    30px; padding-bottom: 30px }
  .md-px1 { padding-left:   30px; padding-right:  30px }

  .md-p2  { padding:        20px }
  .md-pt2 { padding-top:    20px }
  .md-pr2 { padding-right:  20px }
  .md-pb2 { padding-bottom: 20px }
  .md-pl2 { padding-left:   20px }
  .md-py2 { padding-top:    20px; padding-bottom: 20px }
  .md-px2 { padding-left:   20px; padding-right:  20px }

  .md-p3  { padding:        15px; }
  .md-pt3 { padding-top:    15px; }
  .md-pr3 { padding-right:  15px; }
  .md-pb3 { padding-bottom: 15px; }
  .md-pl3 { padding-left:   15px; }
  .md-py3 { padding-top:    15px; padding-bottom: 15px; }
  .md-px3 { padding-left:   15px; padding-right:  15px; }

  .md-p4  { padding:        10px }
  .md-pt4 { padding-top:    10px }
  .md-pr4 { padding-right:  10px }
  .md-pb4 { padding-bottom: 10px }
  .md-pl4 { padding-left:   10px }
  .md-py4 { padding-top:    10px; padding-bottom: 10px }
  .md-px4 { padding-left:   10px; padding-right:  10px }

}



@media all and (max-width: 480px) {


  /*--RESPONSIVE COND--*/
  .sm-only,
  .sm-and-below,
  .mobile-only {
    display: block !important;
  }

  .lg-only,
  .md-only,
  .lg-and-below,
  .md-and-below,

  .not-mobile,
  .tablet-only,
  .above-tab-portrait,
  .tablet-only,
  .desktop-only {
    display: none !important;
  }

  .sm-no-float {
    float: none !important;
  }



  /*--SPACING--*/
  .eighth-section-block {
    margin-bottom:  !important;
  }

  .qtr-section-block {
    margin-bottom:10px  !important;
  }

  .half-section-block {
    margin-bottom: 20px!important;
  }

  .section-block {
    margin-bottom: 45px !important;
  }

  .large-section-block {  
    margin-bottom:  !important;
  }

  .sm-eighth-section-block {
    margin-bottom:  !important;
  }

  .sm-qtr-section-block {
    margin-bottom: 10px !important;
  }

  .sm-half-section-block {
    margin-bottom: 20px  !important;
  }

  .sm-section-block {
    margin-bottom: 45px  !important;
  }

  .sm-large-section-block {
    margin-bottom:  !important;
  }


  /*--WRAPPERS--*/
  .xsmall-wrapper {
    width: ;
    max-width: ;
  }

  .small-wrapper {
    width: 85%;
    max-width:;
  }

  .sm-wrapper,
  .wrapper {
    max-width: 330px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .medium-wrapper {
    width: 80%;
    max-width: 330px;
  }

  .large-wrapper {
    width:90%;
  }

  .xlarge-wrapper {
    width:90%;
  }




  /*--GRID--*/
  .sm-col {
    float: left;
    box-sizing: border-box;
  }

  .sm-col-right {
    float: right;
    box-sizing: border-box;
  }

  .sm-col-1 {
    width: calc(1/12 * 100%);
  }

  .sm-col-2 {
    width: calc(2/12 * 100%);
  }

  .sm-col-3 {
    width: calc(3/12 * 100%);
  }

  .sm-col-4 {
    width: calc(4/12 * 100%);
  }

  .sm-col-5 {
    width: calc(5/12 * 100%);
  }

  .sm-col-6 {
    width: calc(6/12 * 100%);
  }

  .sm-col-7 {
    width: calc(7/12 * 100%);
  }

  .sm-col-8 {
    width: calc(8/12 * 100%);
  }

  .sm-col-9 {
    width: calc(9/12 * 100%);
  }

  .sm-col-10 {
    width: calc(10/12 * 100%);
  }

  .sm-col-11 {
    width: calc(11/12 * 100%);
  }

  .sm-col-12 {
    width: 100%;
  }


  .sm-p0  { padding: 0 }
  .sm-pt0 { padding-top: 0 }
  .sm-pr0 { padding-right: 0 }
  .sm-pb0 { padding-bottom: 0 }
  .sm-pl0 { padding-left: 0 }
  .sm-px0 { padding-left: 0; padding-right:  0 }
  .sm-py0 { padding-top: 0;  padding-bottom: 0 }

  .sm-p1  { padding:        30px }
  .sm-pt1 { padding-top:    30px }
  .sm-pr1 { padding-right:  30px }
  .sm-pb1 { padding-bottom: 30px }
  .sm-pl1 { padding-left:   30px }
  .sm-py1 { padding-top:    30px; padding-bottom: 30px }
  .sm-px1 { padding-left:   30px; padding-right:  30px }

  .sm-p2  { padding:        20px }
  .sm-pt2 { padding-top:    20px }
  .sm-pr2 { padding-right:  20px }
  .sm-pb2 { padding-bottom: 20px }
  .sm-pl2 { padding-left:   20px }
  .sm-py2 { padding-top:    20px; padding-bottom: 20px }
  .sm-px2 { padding-left:   20px; padding-right:  20px }

  .sm-p3  { padding:        15px; }
  .sm-pt3 { padding-top:    15px; }
  .sm-pr3 { padding-right:  15px; }
  .sm-pb3 { padding-bottom: 15px; }
  .sm-pl3 { padding-left:   15px; }
  .sm-py3 { padding-top:    15px; padding-bottom: 15px; }
  .sm-px3 { padding-left:   15px; padding-right:  15px; }

  .sm-p4  { padding:        10px }
  .sm-pt4 { padding-top:    10px }
  .sm-pr4 { padding-right:  10px }
  .sm-pb4 { padding-bottom: 10px }
  .sm-pl4 { padding-left:   10px }
  .sm-py4 { padding-top:    10px; padding-bottom: 10px }
  .sm-px4 { padding-left:   10px; padding-right:  10px }

}
